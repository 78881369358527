/**
 * @class ViewerUrl
 * @classdesc Immutable representation of the "viewer" URL.
 */
class ViewerUrl {
	/**
	 * Parse/generate an infinityy viewer URL. Query string arguments, path parts, etc.
	 * For generating a brand new URL, pass in the root URL to the constructor and then use (the non-mutating /
	 * builder patter) setters.
	 * @param {string|ViewerUrl} args
	 */
	constructor(args) {
		if ('string' === typeof (args)) {
			const url = new URL(args);
			this._rootUrl = url.origin;
			const pathParts = url.pathname.replaceAll(/\/\/+/g, '/').split('/');
			this._setSlug(this._normalizeSlug(pathParts[2]));
			this._parseArguments(url);
		} else { // Assuming a clone operation.
			args = args || {};
			this._rootUrl = args._rootUrl;
			this._brandSlug = args._brandSlug;
			this._chatId = args._chatId;
			this._contentGuid = args._contentGuid;
			this._contentItemId = args._contentItemId;
			this._leadEmail = args._leadEmail;
			this._leadId = args._leadId;
			this._listingId = args._listingId;
			this._noContact = args._noContact;
			this._noRedirect = args._noRedirect;
			this._previewMode = args._previewMode;
			this._sceneContentPath = args._sceneContentPath;
			this._sceneParams = args._sceneParams;
		}
	}

	get brandSlug() {
		return this._brandSlug;
	}

	get chatId() {
		return this._chatId;
	}

	get contentGuid() {
		return this._contentGuid;
	}

	/**
	 * Selected/highlighted content item ID ({type: 'ListingContentItem|ListingUrl', id: number}) in the
	 * info widget and content area.
	 */
	get contentItemId() {
		return this._contentItemId;
	}

	get leadEmail() {
		return this._leadEmail;
	}

	get leadId() {
		return this._leadId;
	}

	/**
	 * Listing ID (numeric) selected in the content area
	 */
	get listingId() {
		return this._listingId;
	}

	get noContact() {
		return this._noContact;
	}

	get noRedirect() {
		return this._noRedirect;
	}

	get previewMode() {
		return this._previewMode;
	}

	get sceneContentPath() {
		return this._sceneContentPath;
	}

	/**
	 * Look direction, zoom, video play params, etc.
	 */
	get sceneParams() {
		return this._sceneParams;
	}

	get slug() {
		return this._contentGuid ? ('-' + this._contentGuid) : this._brandSlug;
	}

	/**
	 * Return a URL to this CONTENT and CHAT, not including the scene or other user-specific params.
	 * @returns
	 */
	cloneAsContentAndChatLink() {
		const clone = new ViewerUrl();
		clone._rootUrl = this._rootUrl;
		clone._brandSlug = this._brandSlug;
		clone._chatId = this._chatId;
		clone._contentGuid = this._contentGuid;
		return clone;
	}

	cloneWithoutTemporaryParams() {
		return this.setNoContact(false).setNoRedirect(false).setLeadEmail().setLeadId();
	}

	/**
	 * Builder pattern returns a new object with the brand slug (brand route) set in canonical form.
	 * @param {string} val
	 * @returns ViewerUrl
	 */
	setBrandSlug(val) {
		const clone = new ViewerUrl(this);
		clone._brandSlug = this._normalizeBrandSlug(val);
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the chat ID set.
	 * @param {number} val
	 * @returns ViewerUrl
	 */
	setChatId(val) {
		const clone = new ViewerUrl(this);
		clone._chatId = +val || null;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the content GUID (project/campaign/listing) set
	 * in canonical form.
	 * @param {string} val
	 * @returns
	 */
	setContentGuid(val) {
		const clone = new ViewerUrl(this);
		clone._contentGuid = val;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the lead ID set.
	 * @param {string} val
	 * @returns ViewerUrl
	 */
	setLeadEmail(val) {
		const clone = new ViewerUrl(this);
		clone._leadEmail = val;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the lead ID set.
	 * @param {number} val
	 * @returns ViewerUrl
	 */
	setLeadId(val) {
		const clone = new ViewerUrl(this);
		clone._leadId = +val || null;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the ListingContentItem ID set.
	 * @param {number} val
	 * @returns ViewerUrl
	 */
	setListingContentItemId(val) {
		const clone = new ViewerUrl(this);
		clone._contentItemId = { type: 'ListingContentItem', id: +val };
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the listing ID set.
	 * @param {number} val
	 * @returns ViewerUrl
	 */
	setListingId(val) {
		const clone = new ViewerUrl(this);
		clone._listingId = +val;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the ListingUrl ID set.
	 * @param {number} val
	 * @returns ViewerUrl
	 */
	setListingUrlId(val) {
		const clone = new ViewerUrl(this);
		clone._contentItemId = { type: 'ListingUrl', id: +val };
		return clone;
	}

	setNoContact(val) {
		const clone = new ViewerUrl(this);
		clone._noContact = val ? true : false;
		return clone;
	}

	setNoRedirect(val) {
		const clone = new ViewerUrl(this);
		clone._noRedirect = val ? true : false;
		return clone;
	}

	setPreviewMode(val) {
		const clone = new ViewerUrl(this);
		clone._previewMode = val ? true : false;
		return clone;
	}

	setRootUrl(val) {
		const clone = new ViewerUrl(this);
		clone._rootUrl = val;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the content path set.
	 * @param {string} val
	 * @returns
	 */
	setSceneContentPath(val) {
		const clone = new ViewerUrl(this);
		clone._sceneContentPath = val;
		return clone;
	}

	/**
	 * Builder pattern returns a new object with the scene params set.
	 * @param {object} val May be an array of numbers or object to be JSONified.
	 * @returns
	 */
	setSceneParams(val) {
		const clone = new ViewerUrl(this);
		clone._sceneParams = val;
		return clone;
	}

	toString() {
		var trimmedRoot = this._rootUrl;
		trimmedRoot = trimmedRoot.replace(/\/+$/, '');
		return `${trimmedRoot}/view/${this.slug}${this._argumentString}`;
	}

	get _argumentString() {
		return this._queryString + this._hashString;
	}

	get _hashString() {
		let hParts = [];
		this._sceneContentPath && hParts.push(`sc=${this._encodeBase64UriComponent(this._sceneContentPath)}`);
		if (this._sceneParams) {
			const encoded = this._encodeBase64UriComponent(JSON.stringify(this._sceneParams));
			hParts.push(`sp=${encoded}`);
		}
		this._listingId && hParts.push(`cl=${this._listingId}`);
		if (['ListingContentItem', 'ListingUrl'].includes(this._contentItemId?.type)) {
			const typeStr = ('ListingContentItem' === this._contentItemId?.type) ? 'lci' : 'lu';
			hParts.push(`ci=${typeStr}:${this._contentItemId.id}`);
		}
		const flagsHex = this._getFlags();
		if (flagsHex) { hParts.push(`f=${flagsHex}`); }
		this._leadEmail && hParts.push(`le=${encodeUIRComponent(this._leadEmail)}`);
		this._leadId && hParts.push(`li=${this._leadId}`);
		return hParts.length ? `#${hParts.join('&')}` : '';

	}

	/**
	 * @returns empty string or question mark prefixed, canonical query string.
	 */
	get _queryString() {
		let qsParts = [];
		this._chatId && qsParts.push(`c=${this._chatId}`);
		return qsParts.length ? `?${qsParts.join('&')}` : '';
	}

	/**
	 * Safe version of base64 decoding that takes modifed base 64 encoding that uses URIComponent-safe
	 * characters
	 */
	_decodeBase64UriComponent(val) {
		return atob(val.replaceAll('-', '+').replaceAll('_', '='));
	}

	/**
	 * Safe version of base64 encoding a string to be used in a URIComponent,
	 * such that it isn't additionally percent encoded.
	 */
	_encodeBase64UriComponent(val) {
		return btoa(val).replaceAll('+', '-').replaceAll('=', '_');
	}

	_getFlags() {
		var numericValue = 0;
		if (this._noContact) { numericValue |= 1; }
		if (this._noRedirect) { numericValue |= 2; }
		if (this._previewMode) { numericValue |= 4; }
		return numericValue ? numericValue.toString(16) : null;
	}

	_setFlags(num) {
		this._noContact = (num & 1);
		this._noRedirect = (num & 2);
		this._previewMode = (num & 4);
		return this;
	}

	_normalizeSlug(slug) {
		return slug?.replaceAll(/[^a-z0-9-]/ig, '')?.replaceAll(/--+/g, '-') || null;
	}

	_parseArguments(url) {
		return this._parseQueryStringOrHash(url.search)._parseQueryStringOrHash(url.hash, true);
	}

	/**
	 * Handle parsing the query string _or_ hash part.
	 * v3 of the URL had project/campaign/listing in the path, everything in the query string.
	 * Still accept this format.
	 * v4 of the URL has project/campaign/listing in the path, chat ID in the query string, everything
	 * else in the hash portion.
	 * @param {string} str The query string or hash inclusive or not of leading '?' or '#'
	 * @param {boolean=} isHash
	 * @returns
	 */
	_parseQueryStringOrHash(str, isHash) {
		str ||= '';
		if (isHash && str.startsWith('#')) {
			str = str.substring(1);
		}

		let tmp;
		new URLSearchParams(str).forEach((value, key) => {
			try {
				switch (key.toLowerCase()) {
					case 'c': // chatId
						if (!isHash) { // Only accepted in the query string part.
							tmp = parseInt(value);
							if (!isNaN(tmp)) { this._chatId = tmp; }
						}
						break;
					case 'ci': // content item
						tmp = value.split(':', 2);
						const id = parseInt(tmp[1]);
						if (!isNaN(id)) {
							if ('lci' === tmp[0]) {
								this._contentItemId = { type: 'ListingContentItem', id: id };
							} else if ('lu' === tmp[0]) {
								this._contentItemId = { type: 'ListingUrl', id: id };
							}
						}
						break;
					case 'cl': // listing id
						tmp = parseInt(value);
						if (!isNaN(tmp)) { this._listingId = tmp; }
						break;
					case 'f':
						tmp = parseInt(value, 16);
						if (!isNaN(tmp)) { this._setFlags(tmp); }
						break;
					case 'le':
						if (value.length) { this._leadEmail = value; }
						break;
					case 'li':
						tmp = parseInt(value);
						if (!isNaN(tmp)) { this._leadId = tmp; }
						break;
					case 'sc': // scene contentPath
						if (value.length) { this._sceneContentPath = this._decodeBase64UriComponent(value); }
						break;
					case 'sp': // scene params like look direction, video location, etc.
						if (value.length) { this._sceneParams = JSON.parse(this._decodeBase64UriComponent(value)); }
						break;
				}
			} catch (e) {
				console.error('[page]', `Failed during query string processing argument ${key}=${value}`,
					e, Date.now() * .001);
			}
		});
		return this;
	}

	_setFlags(num) {
		this._noContact = (num & 1);
		this._noRedirect = (num & 2);
		this._previewMode = (num & 4);
		return this;
	}

	/**
	 * Set either the brand slug or content GUID based on the "/view/slug" slug piece.
	 * @param {string} slug
	 * @returns
	 */
	_setSlug(slug) {
		if (slug?.startsWith('-')) {
			this._brandSlug = null;
			this._contentGuid = slug.substring(1).toLowerCase();
		} else {
			this._brandSlug = slug || '';
			this._contentGuid = null;
		}
		return this;
	}
}

export { ViewerUrl }
